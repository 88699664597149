import React from 'react'
import './penawaran.scss'
import penawaran from '../../assets/Penawaran.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Penawaran = () => {
    const handleConsultationClick = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Consultasi)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
  return (
    <div className='containerPenawaran'>
        <div className='containergambar'>
            <img className='penawarangambar' src={penawaran}/>
        </div>
        <div className='containercontent'>
            <div className='penawarantitle'>
                <li>Our Promo</li>
            </div>
            <div className='penawarantitle2'>Penawaran Terbaik Promo Terbaru!</div>
            <div className='pointpenawaran'>
                
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Siap Huni</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Free PPN</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Tanpa DP</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Free Biaya Surat</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Free Canopy</span>
                    </div>  
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Free AC</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Free CCTV</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Full Smarthome System</span>
                    </div>
                    <div className='penawaranpoin'>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;Free Water Heater</span>
                    </div>
            </div>
            <div className='disclaimer'>*Disclaimer</div> 
            <button className='buttonpenawaran' onClick={handleConsultationClick}>Request Consultation</button>
        </div>
    </div>
  )
}

export default Penawaran;