import astha from '../../assets/Section1/Astha.png'
import basantha from '../../assets/Section1/Basantha.png'
import catriona from '../../assets/Section1/Catriona.png'
import daklota from '../../assets/Section1/Daklota.png'
import eldora from '../../assets/Section1/Eldora.png'
import fedora from '../../assets/Section1/Fedora.png'
import helios from '../../assets/Section1/Helios.png'

const images =[
    astha,
    basantha,catriona,daklota,eldora,fedora,helios
]

export default images;