import React from 'react';
import './header.scss'; // Import the CSS file for styling
import logo from '../../assets/logo.png'



const Header = () => {
  return (
    <header className="header">
      <div className="container">
          <img className="logo" src={logo}/>
          <div className='line'></div>
      </div>
    </header>
  );
};

export default Header;