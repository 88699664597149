import React from 'react'
import './launching.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Launching1 from '../../assets/launching1.jpg'
import Launching2 from '../../assets/launching2.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck,faHouse,faBed,faShower,faArrowsUpDownLeftRight} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const launching = () => {

    const handleConsultationClick = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Consultasi)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const agratipe9 = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Agra%209)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const agratipe8 = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Agra%208)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const agratipe7 = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Agra%207)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
   
       
      };

  return (
    <div className='containerlaunching'>
        <div className='judullaunching'>
            <div className='launchingtitle'>
                <span>New Launching</span>
            </div>
            <div className='buttonlaunching'>
                <button className='tombollaunching' onClick={handleConsultationClick}>Request Consultation</button>
            </div>
        </div>
        <div className='contentlaunching'>
            <div className='carousellaunching'>
                    <div className='carouselcontainer'>
                      <Slider {...settings}> 
                        <img src={Launching1}/>
                        <img src={Launching2}/>
                    </Slider> 
                    </div> 
            </div>
            <div className='cardlaunching'>
                <div className='containercard'>
                    <div className='titlecard'>
                        <div>Agra Tipe 9</div>
                        <span>Alam Sutera</span>
                    </div>
                    <div className='gridspek'>
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 135m<sup>2</sup></span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 142m<sup>2</sup></span>
                    <br/>
                    <FontAwesomeIcon icon={faBed} />
                    <span>: 4+1</span>
                    <FontAwesomeIcon icon={faShower} />
                    <span>: 3+1</span>
                    </div>
                    <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>11</span>
                            <div>Juta/Bulan</div>
                        </div>
                    </div>
                    <div className='containerwhatsapp'>
                        <button onClick={agratipe9}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
                    </div>
                </div>
                <div className='containercard'>
                    <div className='titlecard'>
                        <div>Agra Tipe 8</div>
                        <span>Alam Sutera</span>
                    </div>
                    <div className='gridspek'>
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 120m<sup>2</sup></span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 129m<sup>2</sup></span>
                    <br/>
                    <FontAwesomeIcon icon={faBed} />
                    <span>: 4+1</span>
                    <FontAwesomeIcon icon={faShower} />
                    <span>: 3+1</span>
                    </div>
                    <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>10</span>
                            <div>Juta/Bulan</div>
                        </div>
                    </div>
                    <div className='containerwhatsapp'>
                        <button onClick={agratipe8} className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
                    </div>
                </div>
                <div className='containercard'>
                    <div className='titlecard'>
                        <div>Agra Tipe 7</div>
                        <span>Alam Sutera</span>
                    </div>
                    <div className='gridspek'>
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 105m<sup>2</sup></span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 98m<sup>2</sup></span>
                    <br/>
                    <FontAwesomeIcon icon={faBed} />
                    <span>: 3+1</span>
                    <FontAwesomeIcon icon={faShower} />
                    <span>: 2</span>
                    </div>
                    <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>8</span>
                            <div>Juta/Bulan</div>
                        </div>
                    </div>
                    <div className='containerwhatsapp'>
                        <button onClick={agratipe7}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default launching