import React from 'react'
import './fasility.scss'
import sekolah from '../../assets/sekolah.png'
import tol from '../../assets/tol.png'
import ruko from '../../assets/ruko.png'
import sport from '../../assets/sport.png'
import golf from '../../assets/golf.png'
import bus from '../../assets/bus.png'

const fasility = () => {
  return (
    <div className='containerfasility'>
        <div className='judulfasility'>
            <h3>OUR UPCOMING FASILITY</h3>
        </div>
        <div className='gambarfasilitas'>
            <div className='gambarcontainerfasilitas'>
                <img className='contentfasilitas' src={tol} />
                <div className='overlay'>
                    <div className='judul'>Akses Tol</div>
                </div>
            </div>
            <div className='gambarcontainerfasilitas'>
                <img className='contentfasilitas' src={sekolah} />
                <div className='overlay'>
                    <div className='judul'>Sekolah Laurensia</div>
                </div>
            </div>
            <div className='gambarcontainerfasilitas'>
                <img className='contentfasilitas' src={ruko} />
                <div className='overlay'>
                    <div className='judul'>Ruko Terrace 9</div>
                </div>
            </div>
            <div className='gambarcontainerfasilitas'>
                <img className='contentfasilitas' src={sport} />
                <div className='overlay'>
                    <div className='judul'>Sport Lounge</div>
                </div>
            </div>
            <div className='gambarcontainerfasilitas'>
                <img className='contentfasilitas' src={golf} />
                <div className='overlay'>
                    <div className='judul'>Kedaton Golf</div>
                </div>
            </div>
            <div className='gambarcontainerfasilitas'>
                <img className='contentfasilitas' src={bus} />
                <div className='overlay'>
                    <div className='judul'>Suttle Bus ke Jakarta</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default fasility;