import React, { useState, useEffect } from 'react';
import './section.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from './index.js'

const Sectionone = () => {


  const handleConsultationClick = () => {
    const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Consultasi)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
};
const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
 
};

  return (
    <div className='containersectionone'>
        <div className='content'>
            <div className='title'>
             
              Residential & Business Comunity
             
            </div>
            <div className='description' >
              <p >Suvarna Sutera adalah kawasan hunian mewah yang merupakan bagian dari pengembangan kota mandiri seluas 2.600 hektar di Tangerang, Indonesia</p>
            </div>
            <button className='buttonconsultion' onClick={handleConsultationClick}>Request Consultation</button>
        </div>
        <div className='carrousel'>
       
          <Slider {...settings}>
          {images.map((image, index) => (
            <div>
           
        <img  className='carrouselpicture'key={index} src={image} alt={`Launching ${index + 1}`} />
           
            </div>
            ))}
          </Slider>
      
      </div>
    </div>
  )
}

export default Sectionone;