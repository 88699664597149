import React from 'react'
import Header from '../components/header/header';
import './home.scss'
import Sectionone from '../components/section1/section1';
import Penawaran from '../components/sectionpenawaran/penawaran'
import Launching from '../components/launching/launching';
import Promo from '../components/promo/promo';
import Fasility from '../components/fasility/fasility';
import Footer from '../components/footer/footer';
import tombol from '../assets/tombol.webp'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = () => {

  const tombolwa = () => {
    const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20()%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
};
  return (
    <div className='home'>
        <Header/>
        <Sectionone/>
        <Penawaran/>
        <Launching/>
        <Promo/>
        <Fasility/>
        <Footer/>
        <img onClick={tombolwa} src={tombol} className='tombolwa'/>
        <div className='wamobile'>
          <button className='mobile'>
          <div><FontAwesomeIcon icon={faWhatsapp}/> Whatsapp Sekarang</div>
          </button>
        </div>
    </div>
  )
}

export default Home;