import React from 'react'
import './promo.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from './index.js'
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck,faHouse,faBed,faShower,faArrowsUpDownLeftRight} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
const promo = () => {
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
       
      };
    const importAll = (r) => {
        return r.keys().map(r);
    };
   
    const asthaImages = importAll(require.context('../../assets/Astha', false, /\.(png|jpe?g|svg)$/));
    const catrionaImages = importAll(require.context('../../assets/Catriona', false, /\.(png|jpe?g|svg)$/));
    const basanthaImages = importAll(require.context('../../assets/Basantha', false, /\.(png|jpe?g|svg)$/));
    const fedoraImages = importAll(require.context('../../assets/Fedora', false, /\.(png|jpe?g|svg)$/));
    const merantiImages = importAll(require.context('../../assets/Meranti', false, /\.(png|jpe?g|svg)$/));
    const dakotaImages = importAll(require.context('../../assets/Dakota', false, /\.(png|jpe?g|svg)$/));
    const eldoraImages = importAll(require.context('../../assets/Eldora', false, /\.(png|jpe?g|svg)$/));
    const heliosImages = importAll(require.context('../../assets/Helios', false, /\.(png|jpe?g|svg)$/));

    const wastha = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Ashta)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const wcatriona = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Catriona)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const wbasantha = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Basanta)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const wfedora = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Fedora)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const wmeranti = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Meranti)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const wdakota = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Dakota)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const weldora = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Eldora)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
    const whelios = () => {
        const whatsappLink = "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Suvarna%20Sutera%20(Helios)%20https://suvarna-sutera.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    };
  return (
    <div className='containerpromo'>
        <div className='promotitle'>
            <div className='promojudul'>
                <div>Our Home</div>
                <button className='promobutton'>Request Consultation</button>
            </div>
        </div>
        <div className='promocard'>
            <div className='kartu'>
            <Slider {...settings}>
            {asthaImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Astha</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 80m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 82m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 3 </span>
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 2 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>4</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={wastha} className='whatsapp' ><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>

            <div className='kartu'>
            <Slider {...settings}>
            {catrionaImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Catriona</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 100<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 120<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 4+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>5</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={wcatriona} className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>

            <div className='kartu'>
            <Slider {...settings}>
            {basanthaImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Basanta</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 96m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 122m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 4+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>5</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={wbasantha} className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>

            <div className='kartu'>
            <Slider {...settings}>
            {fedoraImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Fedora</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 100m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 120m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 4+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>6</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={wfedora}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>
            
            <div className='kartu'>
            <Slider {...settings}>
            {merantiImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Meranti</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 280m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 246m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 4+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>8</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={wmeranti}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>

            <div className='kartu'>
            <Slider {...settings}>
            {dakotaImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Dakota</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 135m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 162m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 3+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>10</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={wdakota}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>

            
          
        </div>
        <div className='promocard2'>    
        <div className='kartu'>
            <Slider {...settings}>
            {eldoraImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Eldora</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 240m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 286m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 4+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>16</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={weldora}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>

            <div className='kartu'>
            <Slider {...settings}>
            {heliosImages.map((image, index) => (
            <img className='kartugambar' key={index} src={image} alt={`Astha ${index + 1}`} />
            ))}
            </Slider>
            <div className='titlecard'>
                        <div>Helios Prime</div>
                        <span>Alam Sutera</span>
            </div>
            <div className='gridspek'>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    <span> : 200m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faHouse} />
                    <span> : 237m<sup>2</sup> </span>
                    <FontAwesomeIcon icon={faBed} />
                    <span> : 4+1 </span>
                    <FontAwesomeIcon icon={faShower} />
                    <span> : 3+1 </span>
            </div>
            <div className='harga'>
                        <div className='titleharga'>
                            Start from
                        </div>
                        <div className='jumlahharga'>
                            <span>11</span>
                            <div>Juta/Bulan</div>
                        </div>
            </div>
             <div className='containerwhatsapp'>
                        <button onClick={whelios}className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} />  Whatsapp</button>
                    
            </div>
            </div>
        </div>
    </div>
  )
}

export default promo;