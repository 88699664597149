import React from 'react'
import './footer.scss'
import logo from '../../assets/logo.png'
import master from '../../assets/master.jpg'


const footer = () => {
  return (
    <div className='containerfooter'>
        <div className='linecontainer'>
            <div className='line'/>
        </div>
        <div className='footercontent'>
            <div className='footerlogo'>
                <img className='logo' src={logo}/>
                <div className='description'>Marketing Galery</div>
                <div className='nomor'>Contact : +628228888974</div>
            </div>
            <div className='masterplan'>
                <img className='masterlogo'src={master}/>
            </div>
        </div>
      
    </div>
    
  )
}

export default footer;